import React, { useState } from 'react';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import classNames from 'classnames';
import Pagination, { paginate } from '../components/Pagination';

import PlaceholderImage from './images/media/card_placeholder.png';

import Styles from './MediaSection.module.scss';
import SectionContainer from './SectionContainer';
import { mediaData, thumbnailImage } from './MediaData';


const MediaCard = ({ href, image, date, provider, title }) => (
  <Col md={6}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Card className="mb-3">
        <Container>
          <Row>
            <Col xs={4} sm={4}>
              <div className="thumbnail-wrapper">
                <div className="thumbnail">
                  <div className="centered">
                    <img src={image ?? thumbnailImage(provider)} alt="Placeholder" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={8} sm={8}>
              <CardBody>
                <CardSubtitle>
                  <div className="w-100">

                    <div className={classNames(Styles.provider, "text-small text-gray-dark")}>
                      {provider}
                    </div>
                    <div className={classNames(Styles.date, "text-tiny text-gray")}>
                      {date}
                    </div>
                  </div>
                </CardSubtitle>
                <CardTitle className="text-small text-gray-dark">
                  {title}
                </CardTitle>
              </CardBody>
            </Col>
          </Row>
        </Container>
      </Card>
    </a>
  </Col>
)

const MediaSection = () => {
  const [filteredData, setFilteredData] = useState(paginate(mediaData, 4, 1))
  return (
    <SectionContainer className={Styles.section}>
      <Row >
        <Col lg={3}>
          <h2 className="mb-3">언론·미디어</h2>
        </Col>
        <Col lg={9}>
          {/*<div className="text-right mb-4">
            <div className={Styles['search-form']}>
              <input value="검색어를 입력하세요" className={Styles['search-input']} />
              <button className={Styles['search-button']} />
            </div>
          </div>*/}
          <Row>
            {filteredData.map((data, i) => <MediaCard {...data} key={data.index} />)}
          </Row>
          <div>
            <Pagination onPaginate={setFilteredData} items={mediaData} />
          </div>

        </Col>
      </Row>
    </SectionContainer>
  )
}

export default MediaSection;