import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { Card, CardBody, TabContent, TabPane, Collapse, ListGroup, ListGroupItem, Row, Col, Container } from 'reactstrap';
import Styles from './FAQSection.module.scss';
import SectionContainer from './SectionContainer';

const FAQGroup = ({ children }) => {
  const [openedIndex, setOpenedIndex] = useState(null);

  return (
    <ListGroup>
      {React.Children.map(children, (child, index) => (
        React.cloneElement(child, { onItemClick: setOpenedIndex, index, openedIndex })
      ))}
    </ListGroup>
  )
}

const FAQItem = ({ title, children, onItemClick, index, openedIndex }) => {
  const handleItemClick = useCallback((e) => {
    onItemClick(active?null:index);
  }, [index, openedIndex, onItemClick]);

  const active = index === openedIndex;

  return (
    <>
      <ListGroupItem tag="button" className={Styles['faq-item-header']} onClick={handleItemClick}>
        <div className={classNames(Styles['faq-item-header-caret'], { active })} />
        <span>{title}</span>
      </ListGroupItem>
      <Collapse isOpen={active}>
        <Card className={Styles['faq-item-content']}>
          <CardBody className="text-small text-gray">
            {children}
          </CardBody>
        </Card>
      </Collapse>
    </>
  )
}

const TabButton = ({ onClick, tabId, activeTabId, children }) => {
  const handleClick = useCallback(() => {
    onClick(tabId);
  }, [onClick, tabId]);

  const active = tabId === activeTabId;

  return (
    <ListGroupItem tag="button" onClick={handleClick} active={active} className={Styles.tab}>
      <div className={classNames(Styles['tab-bullet'], { active }, "d-none d-sm-block")} />
      <span className="text-large font-weight-bold">{children}</span>
    </ListGroupItem>
  )
}

const FAQSection = () => {
  const [activeTabId, setActiveTabId] = useState("invest");

  return (
    <SectionContainer>
      <Row className={Styles.section}>
        <Col lg={3}>
          <Row>
            <Col xs={12}>
              <h2 className="mb-3">FAQ</h2>
            </Col>
            <Col xs={12}>
              <ListGroup className={Styles.tabGroup}>
                <TabButton tabId="invest" activeTabId={activeTabId} onClick={setActiveTabId}>투자</TabButton>
                <TabButton tabId="how-to-use" activeTabId={activeTabId} onClick={setActiveTabId}>이용</TabButton>
              </ListGroup>
            </Col>
          </Row>
        </Col>
        <Col lg={9}>
          <TabContent activeTab={activeTabId}>
            <TabPane tabId="invest">
              <FAQGroup>
                <FAQItem title="국내외 ETF에 투자합니다.">
                  <p>
                    빅봇은 ETF 투자를 통해 천 여개의 글로벌 우량종목에 투자합니다.<br />
                    ETF 투자는 리스크를 효과적으로 분산하고 수수료가 적다는 장점이 있습니다.
                  </p>
                  <p>
                    ETF란 간단히 말하면, 개별 종목에 투자하는 것이 아닌 특정 유형/군을 묶어 전체에 투자하는 것을 말합니다.<br />
                    가령 ‘반도체 ETF’에 투자한다고 할 때, A반도체, B반도체 기업에 개별적으로 투자하는 것이 아닌 ‘반도체’업종에 투자하는 것이 됩니다.
                  </p>
                  <p>
                    따라서 A반도체 한 곳이 폭락한다고 반도체 ETF가 폭락하지 않습니다. ETF의 장점이 여기 있습니다.
                  </p>
                  <ul>
                    <li>1만원으로 KODEX반도체200을 사게 되면, 1만원이 200곳의 반도체 기업에 분산투자되어 한 곳이 폭락해도 나머지 199곳의 기업이 손실을 분산해줍니다.</li>
                    <li>펀드의 형태와 유사하지만, 펀드는 사고 팔 수 없는 반면 ETF는 거래가 가능합니다. 우리는 이 거래를 통해 수익을 추구합니다.</li>
                    <li>더불어 거래비용이 낮고 세제 혜택이 있어 일반 대중을 위한 소액투자/분산투자/장기투자에 적합합니다.</li>
                  </ul>
                </FAQItem>
                <FAQItem title="투자 타이밍에 대한 민감도가 낮습니다.">
                  <p>
                    우리가 투자하는 ETF는 분산투자이므로 개별 종목에 비해 변동성이 낮습니다. 따라서 투자 타이밍에 대한 민감도도 낮습니다.
                  </p>
                  <p>
                    더 저렴할 때 투자를 시작하고 싶은 마음은 누구나 같을 것입니다. 그러나 현재의 시점이 저렴한지 비싼지를 판단하는 것은 시간이 지나야만 알 수 있습니다.
                  </p>
                  <p>
                    그럼에도 투자의 타이밍이 고민되신다면, 투자금을 일정 기간에 걸쳐 나누어 입금하는 방법도 고려해볼 수 있습니다.<br />
                    ETF는 타이밍에 대한 고민을 덜고 투자를 시작할 수 있다는 장점이 있습니다.
                  </p>
                </FAQItem>
                <FAQItem title="수익이 발생하지 않을 시 수수료를 받지 않습니다.">
                  <p>
                    빅봇의 수수료는 ‘발생한’ 수익의 1.5%입니다. 수익이 나지 않을 시 수수료를 받지 않습니다.
                  </p>
                  <p>
                    이제까지 대중이 투자서비스에 다가가기 어려웠던 이유 중 하나는 수익이 나든 나지 않든 부과되는 비싼 자문료였습니다.
                    그러나 빅봇의 AI가 수많은 자문인원을 대체할 수 있게 설계되며 더 많은 대중에게 합리적인 수수료로 다가갈 수 있게 되었습니다.
                  </p>
                  <p className="text-tiny">
                    * 세금 : 매매차익에는 세금이 부과되지 않으며, 증권거래세에 한해 매도 시에만 0.3% 부과됩니다.
                  </p>
                </FAQItem>
                <FAQItem title="꾸준한 중수익을 지향합니다.">
                  <p>
                    빅봇은 꿈 같은 일확천금이 아닌 현실적인 자산 형성을 목표로 합니다. 우리의 자산은 억 단위의 거액보다는 일상의 여윳돈이기 때문입니다.
                  </p>
                  <p>
                    따라서 고수익을 내기에 앞서 소중한 재산을 지키는 리스크 관리가 우선시되어야 합니다.
                  </p>
                  <p>
                    현실적인 자산 형성을 위한 가장 확실한 방법은 중수익을 통해 꾸준히 복리를 쌓아가는 것입니다.
                    우리는 일시적인 단타 투자가 아닌 소중한 매일과 함께 누적되는 투자를 지향합니다.
                    일확천금을 꿈 꾸더라도 현실의 자산은 지켜나가야 합니다.
                  </p>
                </FAQItem>
              </FAQGroup>
            </TabPane>
            <TabPane tabId="how-to-use">
              <FAQGroup>
                <FAQItem title="빅봇은 로보어드바이저 서비스입니다.">
                  <p>
                    로보어드바이저란, Robot + Advisor의 합성어로 AI가 투자 포트폴리오 관리를 돕는 서비스를 말합니다.
                    로보어드바이저와 투자전문가 중 어느 쪽이 유능한지에 대해 많은 이야기가 있지만,
                    각 특성과 장점이 너무 명확하기에 우위를 따지기 어렵습니다.
                  </p>
                  <p>
                    로보어드바이저는 전문가의 감각은 지니지 않았지만, 인간에게 부족한 객관적 판단력, 데이터 학습력, 패턴 추론력을 지니고 있습니다.
                    방대한 금융 데이터와 패턴을 익혀 위험을 인지하고 나아가 안전한 대안을 선택하는 일에 뛰어납니다.
                  </p>
                  <p>
                    따라서 소수의 전문가만이 아는 대박을 잡는 일보다 안전하게 여유자금을 굴리는 일에 큰 강점을 지닙니다.
                    로보어드바이저 빅봇은, 소수의 거액 자산가보다 다수의 일반 대중을 향한 서비스를 제공합니다.
                  </p>
                </FAQItem>
                <FAQItem title="맵플러스에서 이용 가능합니다.">
                  <p>
                    빅봇 ETF 투자 전략은 현재 투자 플랫폼 ‘맵플러스’에서 이용하실 수 있습니다.
                  </p>
                  <p>
                    전략명은 ‘Bigbot의 ETF 자산배분’으로, 자문역은 ‘템피스투자자문’으로 등록되어 있습니다.
                  </p>
                  <p>
                    운용을 위한 서비스는 템피스투자자문과 컨소시엄을 맺고 제공하고 있으며,
                    직접 투자자를 위한 빅봇의 자체 어플리케이션은 현재 준비중에 있습니다.
                  </p>
                </FAQItem>
              </FAQGroup>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </SectionContainer>
  )
}
export default FAQSection;