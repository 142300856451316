import React, { useState } from "react";
import { Link } from 'react-scroll';

import Styles from './Navigation.module.scss';

import BIGBOTLogo from './images/logo.png';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container
} from "reactstrap";


const ScrollNavLink = ({ children, ...restProps }) => (
  <NavLink
    href="#"
    tag={(props) => (
      <Link {...props} smooth spy offset={-75}/>
    )}
    {...restProps}
  >
    {children}
  </NavLink>
);


const Navigation = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="light" light expand="md" sticky="top" className={Styles.navigation}>
      <Container>
        <NavbarBrand 
          href="#"
          to="scroll_top"
          tag={((props) => (
            <Link {...props} smooth spy offset={-59}/>
          ))}
        >
          <img src={BIGBOTLogo} alt="BIGBOT"/>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <ScrollNavLink to="scroll_top">홈</ScrollNavLink>
            </NavItem>
            <NavItem>
              <ScrollNavLink to="scroll_how_to_use">이용안내</ScrollNavLink>
            </NavItem>
            <NavItem>
              <ScrollNavLink to="scroll_faq">FAQ</ScrollNavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
