import React, {useState, useEffect, useMemo} from 'react';
import { Pagination as RSPagination, PaginationItem, PaginationLink } from 'reactstrap';
import classNames from 'classnames';
import _ from 'lodash';
import Styles from './Pagination.module.scss';

export const paginate = (array, page_size, page_number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
const Pagination = ({ items = [], pageSize = 4, onPaginate = () => { } }) => {
  const [page, setPage] = useState(0);
  const pageCount = Math.ceil(items.length / pageSize);

  const handlePaginate = (e, newPage) => {
    e.preventDefault();
    setPage(newPage)
    onPaginate(paginate(items, pageSize, newPage + 1))
  }
  
  return (
    <RSPagination listClassName="justify-content-center" className={Styles.pagination}>
      <PaginationItem disabled={page === 0}>
        <PaginationLink first href="#" className={classNames(Styles.first, { disabled: page === 0 })} onClick={(e)=>handlePaginate(e, page-1)}>&lt;</PaginationLink>
      </PaginationItem>
      {pageCount <= 0 && (
        <PaginationItem active>
          <PaginationLink>1</PaginationLink>
        </PaginationItem>
      )}
      {_.range(0, pageCount).map(i => (
        <PaginationItem active={i === page} key={i}>
          <PaginationLink onClick={(e)=>handlePaginate(e, i)}>{i+1}</PaginationLink>
        </PaginationItem>
      ))}

      <PaginationItem disabled={page >= pageCount-1}>
        <PaginationLink last href="#" className={classNames(Styles.first, {disabled:page >= pageCount-1})} onClick={(e)=>handlePaginate(e, page+1)}>&gt;</PaginationLink>
      </PaginationItem>
    </RSPagination>

  )
};

export default Pagination;