import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import classNames from 'classnames'

import BigbotLogo from './images/bigbot_logo.png';
import BigtreeLogo from './images/bigtree_logo.png';
import style from './Footer.module.scss'
import SectionContainer from '../sections/SectionContainer';

const Footer = () => (
  <>
    <SectionContainer className={classNames(style.footer, "background-grey")}>
      <Row>
        <Col md={2} xl={1} className="d-none d-md-block mt-3">
          <img src={BigbotLogo} />
        </Col>
        <Col md={10} xl={11} className="d-none d-md-block">
          <p className={classNames("text-tiny mb-2 mt-4", style.contact_text)}>
            TEL. 02-786-7910&emsp;
            FAX. 02-786-7909&emsp;
            E-MAIL. bigbot@ibigtree.kr
          </p>
          <p className="text-small text-gray">
            (주)빅트리&emsp;<br />
            [대표 : 임홍순]&emsp;
              사업자등록번호 : 140-81-95478<br />
              서울특별시 중구 퇴계로 369 5층 빅트리
          </p>
        </Col>
        <Col md={10} xl={11} xs={9} className="d-block d-md-none">
          <p className={classNames("text-tiny mb-2 mt-4", style.contact_text)}>
            TEL. 02-786-7910 / FAX. 02-786-7909<br />
            E-MAIL. bigbot@ibigtree.kr
          </p>
          <p className="text-tiny text-gray">
            (주)빅트리&emsp;
            [대표 : 임홍순]&emsp;<br />
              사업자등록번호 : 140-81-95478<br />
              서울특별시 중구 퇴계로 369 5층 빅트리
          </p>
        </Col>

      <div className={style.image_container}>
        <img src={BigtreeLogo} />
        </div>
      </Row>
    </SectionContainer>
    <div className="background-darker-grey text-center text-tiny p-2">

      COPYRIGHT 2020, Bigtree CO., LTD. All rights reserved.
    </div>
  </>
)

export default Footer;