import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { Carousel, CarouselItem, CarouselIndicators, Row, Col, Container } from 'reactstrap';

import Step1Image from './images/how_to_use/step1.png';
//import Step2Image from './images/how_to_use/step2.png';
import Step2Image from './images/how_to_use/step2_.png';
import Step3Image from './images/how_to_use/step3.png';
import Step4Image from './images/how_to_use/step4.png';
import CarouselPrevButton from './images/how_to_use/carousel_prev_button.png';
import CarouselNextButton from './images/how_to_use/carousel_next_button.png';
import MaplusLogo from './images/how_to_use/maplus_logo.png';

import Styles from './HowToUseSection.module.scss';
import SectionContainer from './SectionContainer';

const carouselItems = [
  {
    image: Step1Image,
    altText: "맵플러스 앱스토어 스크린샷"
  },
  {
    image: Step2Image,
    altText: "맵플러스 앱 투자전략 목록 스크린샷"
  },
  {
    image: Step3Image,
    altText: "맵플러스 앱 투자전략 상세 내용 및 투자 안내 받기 버튼 스크린샷"
  },
  {
    image: Step4Image,
    altText: "맵플러스 투자준비 스크린샷"
  }
];

const carouselSlides = carouselItems.map((item, index) => (
  <CarouselItem key={index}>
    <div>
      <img src={item.image} alt={item.altText} />
    </div>
  </CarouselItem>
));

const CarouselContainer = ({ carouselIndex, handlePreviousButtonClick, handleNextButtonClick, handleCarouselIndicatorClick }) => (
  <>
    <div className="d-flex justify-content-center">
      <div className={Styles.carouselBackground}>
        <Carousel activeIndex={carouselIndex} previous={handlePreviousButtonClick} next={handleNextButtonClick}>
          {carouselSlides}
        </Carousel>
      </div>
    </div>
    <div className="text-center">
      <CarouselIndicators
        items={carouselSlides}
        activeIndex={carouselIndex}
        onClickHandler={handleCarouselIndicatorClick}
      />
    </div>
  </>
)

const TutorialItem = ({ index, currentIndex, children, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [index, onClick]);

  return (
    <li
      role="button"
      className={classNames(Styles['tutorial-item'], {
        [Styles.active]: index === currentIndex,
      })}
      onClick={handleClick}
    >
      {children}
    </li>
  )
};


const HowToUseSection = () => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleCarouselIndicatorClick = useCallback(index => setCarouselIndex(index), []);
  const handlePreviousButtonClick = useCallback(() => {
    setCarouselIndex(prevIndex => {
      let nextIndex = prevIndex - 1;

      if (nextIndex < 0) {
        nextIndex = carouselItems.length - 1;
      }
      return nextIndex;
    })
  }, []);

  const handleNextButtonClick = useCallback(() => {
    setCarouselIndex(prevIndex => {
      let nextIndex = prevIndex + 1;

      if (nextIndex > carouselItems.length - 1) {
        nextIndex = 0;
      }

      return nextIndex;
    })
  }, []);


  return (
    <SectionContainer className={classNames("background-grey", Styles.section)}>
      <button onClick={handlePreviousButtonClick} className={classNames("d-none d-md-block", Styles['carousel-control-prev'])}>
        <img src={CarouselPrevButton} alt="이전 슬라이드 버튼" />
      </button>
      <button onClick={handleNextButtonClick} className={classNames("d-none d-md-block", Styles['carousel-control-next'])}>
        <img src={CarouselNextButton} alt="다음 슬라이드 버튼" />
      </button>

      <Container>
        <Row>
          <Col sm={12} md={5} className="d-none d-md-block">
            <CarouselContainer
              carouselIndex={carouselIndex}
              handlePreviousButtonClick={handlePreviousButtonClick}
              handleNextButtonClick={handleNextButtonClick}
              handleCarouselIndicatorClick={handleCarouselIndicatorClick}
            />
          </Col>
          <Col xs={12} md={7}>
            <Row>
              <Col xs={12}>

                <h2>빅봇 이용안내</h2>
                <p className="text-large text-gray">
                  ‘빅봇’의 전략은 ‘템피스 투자자문’과 컨소시움을 맺고 있습니다.<br />
                  * ‘빅봇’의 전략은 KB증권에서도 이용하실 수 있습니다.
                </p>
              </Col>
              <Col xs={12} className="d-block d-md-none">
                <CarouselContainer
                  carouselIndex={carouselIndex}
                  handlePreviousButtonClick={handlePreviousButtonClick}
                  handleNextButtonClick={handleNextButtonClick}
                  handleCarouselIndicatorClick={handleCarouselIndicatorClick}
                />
              </Col>
              <Col xs={12} className="text-large text-gray">
                <ol className={Styles.steps} >
                  <TutorialItem index={0} currentIndex={carouselIndex} onClick={setCarouselIndex}>
                    ‘맵플러스’를 다운 받아주세요.
                  </TutorialItem>
                  <TutorialItem index={1} currentIndex={carouselIndex} onClick={setCarouselIndex}>
                    ‘Bigbot’의 투자전략을 선택합니다.
                  </TutorialItem>
                  <TutorialItem index={2} currentIndex={carouselIndex} onClick={setCarouselIndex}>
                    ‘투자 안내 받기’를 클릭합니다.
                  </TutorialItem>
                  <TutorialItem index={3} currentIndex={carouselIndex} onClick={setCarouselIndex}>
                    순서에 따라 계약-계좌-전략 선택을 진행합니다.
                  </TutorialItem>
                </ol>
                <div className={Styles['maplus-logo']}>
                  <img src={MaplusLogo} alt="맵플러스 로고" />
                </div>
              </Col>

            </Row>
          </Col>
        </Row>
      </Container>
    </SectionContainer>
  )
}

export default HowToUseSection;