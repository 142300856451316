import React from 'react';
import { hot } from 'react-hot-loader';
import { Element } from 'react-scroll';

import './App.css';

import TeaserSection from './sections/TeaserSection';
import CertificationSection from './sections/CertificationSection';
import KeyFeaturesSection from './sections/KeyFeaturesSection';
import HowItWorksSection from './sections/HowItWorksSection';
import HowToUseSection from './sections/HowToUseSection';
import FAQSection from './sections/FAQSection';
import TestimonySection from './sections/TestimonySection';
import MediaSection from './sections/MediaSection';

import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  return (
    <div className="App">
      <ScrollToTopButton/>
      <Navigation/>
      <div className="sections">
        <Element name="scroll_top">
          <TeaserSection />
          <CertificationSection/>
          <KeyFeaturesSection/>
          <HowItWorksSection/>
        </Element>        
        <Element name="scroll_how_to_use">
          <HowToUseSection/>
        </Element>
        <Element name="scroll_faq">
          <FAQSection/>
        </Element>
        <TestimonySection/>
        <MediaSection/>
      </div>
      <Footer/>
    </div>
  );
}

export default hot(module)(App);
