import React from 'react';
import { Container } from 'reactstrap';

export const SectionContainer = ({ children, ...props }) => {
  return (
    <div {...props}>
      <Container className="section-container">{children}</Container>
    </div>
  );
};
export default SectionContainer;
