import React from 'react';
import classNames from 'classnames';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';

import PlaceholderImage from './images/testimony/card_placeholder.png';
import image1 from './images/testimony/card1.png';
import image2 from './images/testimony/card2.png';
import image3 from './images/testimony/card3.png';

import Styles from './TestimonySection.module.scss';
import SectionContainer from './SectionContainer';
import ScrollAnimation from 'react-animate-on-scroll';


const testimonyData = [
  {
    title: "다이나믹하기보단 편안한 투자이기 때문에 다이나믹의 위험성을 느껴본 분들에게 추천하고 싶습니다.",
    interviewee: "이동규님",
    url: "https://blog.naver.com/ibigtreekr/221808966425",
    image: image1
  },
  {
    title: "사회 초년생인 저의 아들도 빅봇 상품에 가입했습니다. 가장 중요한 이유는 자산배분 모델이",
    interviewee: "박재훈님",
    url: "https://blog.naver.com/ibigtreekr/221799681337",
    image: image2
  },
  {
    title: "수십 수백 번의 알고리즘 백테스팅을 통해 수익률을 개선하고 포트폴리오 안정성을 높이는",
    interviewee: "심규호님",
    url: "https://blog.naver.com/ibigtreekr/221797457841",
    image: image3
  }
]

const TestimonyCard = ({ title, children, href, image }) => (
  <Col md={4} className={Styles.cardContainer}>
    <ScrollAnimation animateIn="fadeInUp" offset={20}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <Card className={Styles.card}>
          <CardImg top width="100%" src={image} />
          <CardBody>
            <CardTitle className="text-small">{title}</CardTitle>
            <CardSubtitle className="text-tiny">INTERVIEW</CardSubtitle>
            <CardText className="text-tiny">
              {children}
            </CardText>
          </CardBody>
        </Card>
      </a>
    </ScrollAnimation>
  </Col>
);

const testimonies = testimonyData.map((data, i) => (
  <TestimonyCard key={i} title={data.title} href={data.url} image={data.image}>{data.interviewee}</TestimonyCard>
));


const TestimonySection = () => (
  <SectionContainer className={classNames("background-blue", "text-center", Styles.section)}>
    <Container>
      <h3 className="text-white font-weight-bolder">빅봇을 이용한 사람들</h3>
      <Row>
        {testimonies}
      </Row>
    </Container>
  </SectionContainer>
);

export default TestimonySection;