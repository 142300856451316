import defaultImage from './images/media/card_placeholder.png'
import thebell from './images/media/thebell.png'
import newsprime from './images/media/newsprime.png'
import kosme from './images/media/kosme.png'
import econovill from './images/media/econovill.png'
import fntimes from './images/media/fntimes.gif'
import boannews from './images/media/boannews.gif'
import digitaltoday from './images/media/digitaltoday.png'
import mtn from './images/media/mtn.png'
import ddaily from './images/media/ddaily.gif'
import cnbc from './images/media/cnbc.jpg'
import kmib from './images/media/kmib.png'
import newspim from './images/media/newspim.jpg'
import viva100 from './images/media/viva100.png'

const IMAGE_MAP = {
  "더벨": thebell,
  "기업나라": kosme,
  "프라임경제": newsprime,
  "이코노믹리뷰": econovill,
  "한국금융신문": fntimes,
  "보안뉴스": boannews,
  "디지털투데이": digitaltoday,
  "머니투데이": mtn,
  "디지털데일리": ddaily,
  "cnbc": cnbc,
  "국민일보": kmib,
  "뉴스핌": newspim,
  "브릿지경제": viva100

}
export const thumbnailImage = (provider) => {
  return IMAGE_MAP[provider] ?? defaultImage
}
export const mediaData = [
  {
    index: 1,
    date: "2019-04-15",
    title: "템피스자문, '로보 앱' 구축위한 실탄확보",
    href: "http://www.thebell.co.kr/free/content/ArticleView.asp?key=201904120100024230001502&svccode=00&page=1&sort=thebell_check_time",
    provider: "더벨",

  },
  {
    index: 2,
    date: "2019-04-01",
    title: "자산관리 이제 AI가 똑똑하게",
    href: "http://nara.kosmes.or.kr/newshome/mtnmain.php?mtnkey=articleview&mkey=bestlist&mkey2=45&aid=5703&bpage=7&stext=&smelink=",
    provider: "기업나라",

  },
  {
    index: 3,
    date: "2017-08-30",
    title: '핀테크 오픈플랫폼 1주년 "새로운 생태계 형성 기대"',
    href: "http://www.newsprime.co.kr/news/article/?no=386415",
    provider: "프라임경제",

  },
  {
    index: 4,
    date: "2017-08-03",
    title: '[머니엑스포 딥러닝 재테크 ①] 로보어드바이저 만난 자산관리 ‘르네상스’',
    href: "http://www.econovill.com/news/articleView.html?idxno=319694",
    provider: "이코노믹리뷰",

  },
  {
    index: 5,
    date: "2017-06-07",
    title: '테스트 통과 5개 로보어드바이저…자산배분·개인관리 차별화 시도',
    href: "https://cnews.fntimes.com/html/view.php?ud=182548",
    provider: "한국금융신문",

  },
  {
    index: 6,
    date: "2017-06-01",
    title: '상용화 중인 로드어드바이저 관련 핀테크 기술 한 곳에서 만나',
    href: "https://www.boannews.com/media/view.asp?idx=55052&kind=2",
    provider: "보안뉴스",

  },
  {
    index: 7,
    date: "2017-06-01",
    title: '우리가 로보어드바이저 선도기업',
    href: "http://www.digitaltoday.co.kr/news/articleView.html?idxno=107639",
    provider: "디지털투데이",

  },
  {
    index: 8,
    date: "2017-05-31",
    title: '핀테크 데모데이 개최…아이디어 공모전에 14팀 수상',
    href: "http://news.mtn.co.kr/newscenter/news_viewer.mtn?gidx=2017053115551230952",
    provider: "머니투데이",

  },
  {
    index: 9,
    date: "2017-05-31",
    title: '핀테크지원센터, 제 17차 핀테크 데모데이 개최',
    href: "http://www.ddaily.co.kr/news/article/?no=156552",
    provider: "디지털데일리",

  },
  {
    index: 10,
    date: "2017-04-28",
    title: '로보어드바이저 협의회, 제1차 운영회의 공식 개최',
    href: "https://cnbc.sbs.co.kr/article/10000857276?division=NAVER",
    provider: "cnbc",

  },
  {
    index: 11,
    date: "2017-03-23",
    title: '지니앤컴퍼니, 로보어드바이저 회사 빅트리와 MOU',
    href: "http://news.kmib.co.kr/article/view.asp?arcid=0011349355&code=61141411&cp=nv",
    provider: "국민일보",

  },
  {
    index: 12,
    date: "2016-11-18",
    title: "로보어드바이저 'DNA‧인텔리퀀트', 트럼프 쇼크 뛰어넘다",
    href: "http://www.newspim.com/news/view/20161118000154",
    provider: "뉴스핌",

  },
  {
    index: 13,
    date: "2016-09-30",
    title: "코스콤, 핀테크 공모 본선 진출社로 11곳 선정",
    href: "http://news.mtn.co.kr/newscenter/news_viewer.mtn?gidx=2016093012252434241",
    provider: "머니투데이",

  },
  {
    index: 14,
    date: "2016-09-07",
    title: "코스콤, 기업 11곳과 핀테크 오픈플랫폼 스타트업 MOU",
    href: "http://www.viva100.com/main/view.php?key=20160907010002038",
    provider: "브릿지경제",

  },
]