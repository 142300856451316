import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
import Styles from './TeaserSection.module.scss';


const TeaserCarouselContent = ({ imageClassName, children }) => (
  <div className={Styles['carousel-content']}>
    <div className={classNames(Styles['carousel-right'], Styles.image, imageClassName)} />
    <div className={classNames(Styles.innerBox, "d-flex align-items-center")}>
      {children}
    </div>
  </div>
);

const carouselItems = [
  {
    content: (
      <TeaserCarouselContent imageClassName={Styles.image1}>
        <h1>
          투자관리는<br />
          <b>AI가</b><br className="mt-4" />
          삶의 여유는<br />
          <b>내가</b>
        </h1>
      </TeaserCarouselContent>
    )
  },
  {
    content: (
      <TeaserCarouselContent imageClassName={Styles.image2}>
        <h1>
          투자도<br />
          저축처럼<br />
          <b>꾸준하게</b>
        </h1>
      </TeaserCarouselContent>
    )
  },
  {
    content: (
      <TeaserCarouselContent imageClassName={Styles.image3}>
        <h1>
          경제적 여유로<br />
          <b>
            내 삶의 주도권을<br />
            더욱 튼튼하게
          </b>
        </h1>
      </TeaserCarouselContent>
    )
  }
]

const carouselSlides = carouselItems.map((item, index) => (
  <CarouselItem key={index}>
    {item.content}
  </CarouselItem>
));

const TeaserSection = () => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handlePreviousButtonClick = useCallback(() => {
    setCarouselIndex(prevIndex => {
      let nextIndex = prevIndex - 1;

      if (nextIndex < 0) {
        nextIndex = carouselItems.length - 1;
      }

      return nextIndex;
    })
  }, []);

  const handleNextButtonClick = useCallback(() => {
    setCarouselIndex(prevIndex => {
      let nextIndex = prevIndex + 1;

      if (nextIndex > carouselItems.length - 1) {
        nextIndex = 0;
      }

      return nextIndex;
    })
  }, []);

  return (
    <div className={Styles.section}>
      <div className={Styles.background} />
      <CarouselIndicators
        items={carouselSlides}
        activeIndex={carouselIndex}
        onClickHandler={setCarouselIndex}
        className={Styles.carouselIndicator}
      />
      <Carousel activeIndex={carouselIndex} previous={handlePreviousButtonClick} next={handleNextButtonClick}>
        {carouselSlides}
      </Carousel>
    </div>
  );
}

export default TeaserSection;