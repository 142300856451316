import React from 'react';
import classNames from 'classnames';
import { Container, Row, Col } from 'reactstrap';

import AssetDistributionFigureImage from './images/how_it_works/asset_distribution.png';
import AssetDistributionFigureImage1 from './images/how_it_works/asset_distribution_1.png';
import AssetDistributionFigureImage2 from './images/how_it_works/asset_distribution_2.png';
import ProfessionalCompaniesLogo from './images/how_it_works/professional_companies_logo.png';
import DiagnoseImage from './images/how_it_works/diagnose.png';
import AnalyzeImage from './images/how_it_works/analyze.png';
import ConstructImage from './images/how_it_works/construct.png';
import OptimizeImage from './images/how_it_works/optimize.png';
import TempisLogo from './images/how_it_works/tempis_logo.png';
import TempisLogo2 from './images/how_it_works/tempis_logo2.png';

import Styles from './HowItWorksSection.module.scss';
import SectionContainer from './SectionContainer';
import ScrollAnimation from 'react-animate-on-scroll';

import { Link } from 'react-scroll';

const FeatureRow = ({ children }) => (
  <div className={Styles.feature}>
    <Row>
      {children}
    </Row>
  </div>
)

const FeatureRowColumn = ({ children, ...props }) => (
  <Col md={6} {...props}>
    {children}
  </Col>
)

const FeatureMainText = ({ children }) => (<h3 className="text-navy-light"><b>{children}</b></h3>)
const FeatureSubText = ({ children }) => (<p className="text-large text-gray">{children}</p>)

const UpperSection = () => (
  <SectionContainer className={Styles.section_upper}>
    <FeatureRow>
      <FeatureRowColumn>
        <FeatureMainText>
          자동화된 투자로<br />
          간편하게 관리하세요.
        </FeatureMainText>
        <FeatureSubText>
          AI빅봇이 내 성향과 시장 변화에 맞는
        투자 전략을 제시하고 진행합니다.<br />
          무분별한 정보 속에서
          나만의 투자를 유지하세요.
        </FeatureSubText>
      </FeatureRowColumn>
      <FeatureRowColumn>
        <ScrollAnimation animateIn="fadeInUp" offset={20}>
          <div >
            <div className={Styles.productBox}>
              <h3 className="font-weight-bolder">Bigbot의 ETF 자산배분</h3>
              <div className={Styles.productName}>
                <div className={Styles.tempisLogo}>
                  <img src={TempisLogo2} alt="템피스 투자자문 로고" className="mr-3" />
                </div>
                <span className="text-small">템피스 투자자문</span>
              </div>
              <span className={classNames(Styles.productDescription, "text-small text-gray-light")}>
                딥러닝 기반의 전술적 국내 ETF 자산배분전략<br />
                <span className="text-black">최소가입 50만원   운용보수 0.0%(성과형)</span>
              </span>
            </div>
            <Link to="scroll_how_to_use" smooth spy offset={-75} className={classNames(Styles.button)}>
              <span className="text-small">투자 안내받기</span>
            </Link>
          </div>
        </ScrollAnimation>
      </FeatureRowColumn>
    </FeatureRow>
    <FeatureRow>
      <FeatureRowColumn>
        <FeatureMainText>
          천 여개 종목에 분산 투자하여<br />
          최대 네 배 안전합니다.
        </FeatureMainText>
        <FeatureSubText>
          2008.01~2019.4 백테스팅 결과<br />
          빅봇 ETF 표준형 최대 손실률 : -12.4%<br />
          KOSPI 최대 손실률 : -50.3%
        </FeatureSubText>
        <span className="text-small text-gray mt-3 d-none d-md-block">* 백테스팅 결과가 미래의 수익률을 보장하는 것은 아닙니다 </span>
      </FeatureRowColumn>
      <FeatureRowColumn className="text-center">
        <Row>
          <Col xs={12}>
            <span className="text-large text-gray mb-2 d-block">김빅봇님의 자산</span>
          </Col>
          <Col xs={12} style={{ zIndex: 200 }}>
            <ScrollAnimation animateIn="fadeInUp" offset={20}>
              <img src={AssetDistributionFigureImage1} alt="자산을 여러 종목에 분산합니다." />
            </ScrollAnimation>
          </Col>
          <Col xs={12} style={{ marginTop: -30, overflow: "hidden" }}>
            <img src={AssetDistributionFigureImage2} alt="자산을 여러 종목에 분산합니다." />
            <ScrollAnimation
              animateIn="fadeOutDown"
              offset={0}
              delay={700}
              initiallyVisible
              style={{
                backgroundColor: 'white',
                width: '100%',
                height: '100%',
                zIndex: 100,
                position: 'absolute',
                top: 0
              }}
            />
          </Col>
        </Row>
        <span className="text-small text-gray mt-3 d-block d-md-none" style={{ zIndex: 150 }}>* 백테스팅 결과가 미래의 수익률을 보장하는 것은 아닙니다 </span>
      </FeatureRowColumn>
    </FeatureRow>
    <FeatureRow>
      <FeatureRowColumn>
        <FeatureMainText>
          AI와 금융 전문가가<br />
          함께합니다.
        </FeatureMainText>
        <FeatureSubText>
          AI 빅봇이 20년치 금융 데이터를
          학습해 전략을 도출하면 20년 경력의
          금융 전문가가 예외의 상황을 조율합니다.<br />
          인간의 감정적 판단을 보완하고 투자를 최적화하세요.
        </FeatureSubText>
      </FeatureRowColumn>
      <FeatureRowColumn className="text-center">
        <ScrollAnimation animateIn="fadeInUp" offset={20}>
          <div className={classNames(Styles['companies-image'])}>
            <img
              src={ProfessionalCompaniesLogo}
              alt="AI 금융 전문가 BIGBOT, TEMPIS, maplus 로고"
            />
          </div>
        </ScrollAnimation>
      </FeatureRowColumn>
    </FeatureRow>
  </SectionContainer>
);

const LowerTitle = ({ children }) => (<h3 className="font-weight-bolder text-navy-light mt-3">{children}</h3>)
const LowerText = ({ children }) => (<p className="text-large text-gray font-weight-bolder">{children}</p>)
const LowerCard = ({ children, title, text, src, alt }) => (
  <Col lg={3} sm={6} className="mb-5">
    <img src={src} alt={alt} />
    <ScrollAnimation animateIn="fadeInUp" offset={10}>
      <LowerTitle>{title}</LowerTitle>
      <LowerText>
        {text}
      </LowerText>
    </ScrollAnimation>
  </Col>
)
const LowerSection = () => (
  <SectionContainer className={classNames("text-center", Styles.section_lower)}>
    <h2>빅봇은 이렇게 운용됩니다.</h2>
    <Row>
      <LowerCard
        src={DiagnoseImage} alt={"분석 단계"}
        title={"고객 성향 진단"}
        text={"입력된 고객 데이터를 학습하여 빅봇이 고객의 투자 성향을 진단합니다."}
      />
      <LowerCard
        src={OptimizeImage} alt={"최적화 단계"}
        title={"포트폴리오 최적화"}
        text={"시장 상황과 포트폴리오 성과를 조율하여 위험은 최소화하고 수익은 최대화할 수 있도록 합니다."}
      />
      <LowerCard
        src={ConstructImage} alt={"구성 단계"}
        title={"포트폴리오 최적화"}
        text={"금융 데이터와 시장 추세를 학습하여 고객 투자 성향에 맞는 포트폴리오를 구성합니다."}
      />
      <LowerCard
        src={AnalyzeImage} alt={"성과 분석 및 대응 단계"}
        title={"성과 분석 및 대응"}
        text={"투자 성과를 분석하고 시장 변화에 대응하여 포트폴리오를 지속적으로 최적화합니다."}
      />
    </Row>
  </SectionContainer>
);


const HowItWorksSection = () => (
  <>
    <UpperSection />
    <LowerSection />
  </>
)

export default HowItWorksSection;