import React from 'react';
import classNames from 'classnames';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

import Styles from './KeyFeaturesSection.module.scss';
import SectionContainer from './SectionContainer';


const Feature = ({ beforeTitle, afterTitle, children }) => (

  <Col lg={3}>
    <ScrollAnimation animateIn="fadeInUp" offset={20}>
      <div className={Styles.feature}>
        <Row className="align-items-center">
          <Col xs={6} lg={12}>
            <h3 className={Styles.before}>{beforeTitle}</h3>
          </Col>
          <Col xs={6} lg={12} className={Styles.after}>
            <h3><b>{afterTitle}</b></h3>
            <p className="text-large">{children}</p>
          </Col>
        </Row>
      </div>
    </ScrollAnimation>
  </Col>
);

const KeyFeaturesSection = () => (
  <SectionContainer className={classNames("background-blue text-center", Styles.section)}>
    <h2 className="text-white mt-5">투자도 안전하게 할 수 있을까?</h2>
    <h3 style={{ color: "#efefef" }}>
      근로소득만으로는 부족한 오늘날,<br />
      더 많은 사람들이 투자소득에 다가갈 수 있도록.
    </h3>
    <div className={'position-relative w-100 pt-4 pb-5'}>
      <div className={classNames(Styles.vertical_line)}></div>
    </div>
    <Row className={classNames(Styles.features)}>
      <Feature beforeTitle="높은 시장 변동성" afterTitle="글로벌 분산 투자">
        국내외 천 여개
        주식, 상품 채권에
        리스크를 분산
      </Feature>
      <Feature beforeTitle="제한된 정보" afterTitle={<>글로벌 시장 정보 수집</>}>
        24시간 국내외
        금융시장 정보 수집
      </Feature>
      <Feature beforeTitle="탐욕과 공포" afterTitle="AI 원칙 투자">
        시장 데이터에 따른
        객관적 판단과
        신속한 전략 수행
      </Feature>
      <Feature beforeTitle="비싼 수수료" afterTitle="기본 수수료 무료">
        수익 발생 시,
        운용 수익에 대한
        성과 수수료만 책정
      </Feature>
    </Row>

  </SectionContainer>
)

export default KeyFeaturesSection;