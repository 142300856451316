import React from 'react';
import classNames from 'classnames';
import { Container, Row, Col } from 'reactstrap';

import FSCLogo from './images/certification/fsc_logo.png';
import TestBedCenterLogo from './images/certification/testbed_center_logo.png';

import Styles from './CertificationSection.module.scss';
import SectionContainer from './SectionContainer';

const CertificationSection = () => (
  <SectionContainer className={classNames("background-grey", Styles.section)}>
    <Row>
      <Col xs={6} className="text-sm-right">
        <img src={FSCLogo} alt="금융위원회 로고" />
      </Col>
      <Col xs={6} className="text-sm-left">
        <img src={TestBedCenterLogo} alt="로보 어드바이저 테스트베드 센터 로고" />
      </Col>
    </Row>
    <div className={classNames("text-center", Styles.text)}>
      <p>
        <b>빅봇은 금융위원회가 주관한 테스트베드에 정식 통과하여</b><br />
        유효성, 안전성을 검증받은 로보어드바이저입니다.
      </p>
    </div>
  </SectionContainer>
)
export default CertificationSection;